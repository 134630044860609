import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Container = styled.div`
  margin: 40px auto;
  max-width: 800px;
`;

const Header = styled.div`
  h1 {
    font-weight: 400;
  }
`;

const Button = styled.div`
  padding: 6px 18px;
  border: 2px solid black;
  background: ${(props) => (props.active ? "black" : "white")};
  color: ${(props) => (props.active ? "white" : "black")};
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
`;

const InlineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AlbumHeader = styled.div`
  margin-top: 40px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  padding-right: 20px;
`;

const AlbumHeaderLeft = styled.div`
  background: black;
  color: white;
  font-size: 18px;
  height: calc(100% + 30px);
  padding: 10px 20px;
`;

const Track = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const albums = [
  ["39Ad3MRvUNu9nSw4m8zJUg", "All Nighter Vol. 1"],
  ["2eA0xNveoo8V6YvvEYj05y", "Afterparty"],
  ["1Jz8VING3PnZFbgAGQAXgb", "All Nighter Vol. 2"],
  ["3axDXu7qhi384KuWvngplu", "All Nighter Vol. 2 - Remixes"],
  ["62zC6lrlyJkcZNF57oH1Uo", "All Nighter Vol. 3"],
  ["5Lm0boDYOTXGZDhetvy9sC", "All Nighter Vol. 4"],
  ["6hmW7q3CTdAjweTGyNW0C8", "Telephone"],
  ["0SrVGDtq1IIfgIFHnq5COj", "Telephone Redialed"],
  ["64Ke0JUePc7RniUZQcNLF2", "All Nighter Vol. 5"],
  ["1Thi7GCXPtyG7NfXBOYCTS", "All Nighter Vol. 6"],
  ["6KDUb4drDTwYtQNmcD7A62", "Storybook"],
  ["4Nc6cTaCq9RU1hrODsY3cx", "All Nighter Vol. 7"],
  ["7DMxq9XaYVnYgBhULhtSik", "Storybook Chapter 2"],
  ["1MV3GAeXUNCwu2Nj9M5oYX", "All Nighter Vol. 8"],
  ["05hTpnpWAQ56UF3tmVBS8p", "All Nighter Vol. 9"],
];

function App() {
  let [playCounts, setPlayCounts] = useState([]);
  let [sort, setSort] = useState("album");

  useEffect(() => {
    async function getPlayCounts(id, name) {
      const req = await axios.get(
        "https://api.t4ils.dev/albumPlayCount?albumid=" + id
      );
      return req.data.data.discs[0].tracks.map((i) => ({
        title: i.name,
        plays: i.playcount,
        album: id,
        artists: i.artists.map((i) => i.name).join(", "),
      }));
    }
    const arr = albums.map((i) => getPlayCounts(i[0], i[1]));
    Promise.all(arr).then((res) => setPlayCounts(res.flat(1)));
  }, [sort, setPlayCounts]);

  return (
    <Container>
      <Header>
        <h1>
          <strong>FORM </strong>Spotify Analytics
        </h1>
        <p>
          This site pulls play counts from Spotify for all FORM releases to make
          them more easily viewable in one place.
        </p>
        <InlineDiv>
          <h3>Sort By:</h3>
          <div>
            <Button active={sort === "album"} onClick={() => setSort("album")}>
              Album
            </Button>
            <Button
              active={sort === "playCount"}
              onClick={() => setSort("playCount")}
            >
              Play Count
            </Button>
          </div>
        </InlineDiv>
      </Header>
      {playCounts.length === 0 && <h2>Loading...</h2>}
      {playCounts.length > 0 && sort === "playCount" && (
        <div>
          <AlbumHeader>
            <AlbumHeaderLeft>Total</AlbumHeaderLeft>
            <span>{playCounts.reduce((i, p) => i + p.plays, 0)} plays</span>
          </AlbumHeader>
          {playCounts
            .sort((a, b) => b.plays - a.plays)
            .map((i) => (
              <Track key={i.album + "/" + i.title}>
                <span>
                  <span>{i.title}</span>
                </span>
                <span>{i.plays || "<1000"}</span>
              </Track>
            ))}
        </div>
      )}
      {playCounts.length > 0 && sort === "album" && (
        <div>
          <AlbumHeader>
            <AlbumHeaderLeft>Total</AlbumHeaderLeft>
            <span>{playCounts.reduce((i, p) => i + p.plays, 0)} plays</span>
          </AlbumHeader>
          {albums.map((album) => (
            <div key={album[0]}>
              <AlbumHeader>
                <AlbumHeaderLeft>{album[1]}</AlbumHeaderLeft>
                <span>
                  {playCounts
                    .filter((i) => i.album === album[0])
                    .reduce((i, p) => i + p.plays, 0)}{" "}
                  plays
                </span>
              </AlbumHeader>
              {playCounts
                .filter((i) => i.album === album[0])
                .map((i) => (
                  <Track key={i.album + "/" + i.title}>
                    <span>
                      <span>{i.title}</span>
                    </span>
                    <span>{i.plays || "<1000"}</span>
                  </Track>
                ))}
            </div>
          ))}
        </div>
      )}
    </Container>
  );
}

export default App;
